
import { defineComponent, ref, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { DELETE_SITE } from "../graphql/Mutations";
import { GET_SITES, SEARCH_SITES } from "@/modules/common/sites/graphql/Queries";
import Search from "../../../../components/search/Search.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "site-list",
	components: { Search, Table, InnerLoader },
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const emitter: any = inject("emitter");
		const loader = ref(false);
		const loading = ref(false);
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);

		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.TYPE",
				key: "type",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.PRICE_WITH_TAX",
				key: "price",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		const siteData = computed(() => {
			return store.getters.getAllSites.data.sites;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const handleEdit = (site: Record<any, any>) => {
			emitter.emit("editSiteActions", site);
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_SITE,
						variables: { id: id },
						update: (store, { data: { delete_site } }) => {
							const read_data = store.readQuery({
								query: GET_SITES,
							}) as Record<any, any>;
							const data = read_data.sites.filter((t: any) => t.id !== delete_site.id);
							store.writeQuery({
								query: GET_SITES,
								data: {
									sites: [...data],
								},
							});
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		const searchHandler = (reload = false) => {
			const searchDataInput = reload == true ? "" : store.getters.getSearchData.data.input;
			loader.value = true;
			Apollo.watchQuery({
				query: SEARCH_SITES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				variables: {
					search_key: searchDataInput,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}

				store.dispatch(Actions.SET_SITES, {
					data: { sites: data.search_sites },
				});
				loader.value = false;
			});
		};

		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
		});

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_SITES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_sites[0]?.path, "_blank");
					loading.value = false;
				}
				loading.value = false;
				sub.unsubscribe();
			});
		};

		const getAllSites = () => {
			searchHandler(true);
		};

		return {
			siteData,
			columns,
			permissions,
			loader,
			loading,
			handleEdit,
			handleDelete,
			getAllSites,
			searchHandler,
			exportFile,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
