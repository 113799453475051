
import {setPageHeader,} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue"
import AddSite from '@/modules/system/sites/components/AddSite.vue'
import EditSite from '@/modules/system/sites/components/EditSite.vue'
import SitesList from "@/modules/system/sites/components/SitesList.vue"

export default defineComponent({
	name: "sites",
	components:{
		SitesList,
		AddSite,
		EditSite
	},
	setup() {
		onMounted(() => {
            setPageHeader({
                title: "message.SITES",
                actionButton: {
                    ability:'add_site',
                    pageAction: {
                        action: "addSiteAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.SITES"}
                ]
            })
		});
	},

	props: {
		widgetClasses: String,
	},
});
