
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { SiteAddFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { CREATE_SITE } from "../graphql/Mutations";
import { GET_SITES } from "@/modules/common/sites/graphql/Queries";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new site",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addSiteForm = ref<null | HTMLFormElement>(null);
		const countries = ref([]) as Record<any, any>;
		const showCountryInfo = ref([]) as Record<any, any>;

		// Given Site Form Interface
		const siteData = ref<SiteAddFormData>({
			name: "",
			type: "",
			country_id: null,
			status: 1,
			prices_with_tax: 1,
			account_id: "",
		});

		const type = ref([
			{ label: "B2C", value: "B2C" },
			{ label: "B2B", value: "B2B" },
		]);

		// Get Countries
		const getCountries = () => {
			countries.value = [];
			const countriesData = Apollo.readQuery({
				query: GET_COUNTRIES,
			});

			const country = [] as Record<any, any>;
			countriesData.countries.forEach((element) => {
				country.push({
					label: element.name,
					value: element.id,
					locale: element.locale,
					currency: element.currency,
				});
			});

			countries.value = country;
		};

		getCountries();

		const changeConfig = (id: number) => {
			showCountryInfo.value = [];
			const config = countries.value.find((element) => element.value == id);
			showCountryInfo.value.push({
				Currency: config.currency,
				Locale: config.locale,
			});
		};

		// Submit Hanlder Request`
		const submitHandlerForm = async () => {
			addSiteForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						type: siteData.value.type,
						country_id: siteData.value.country_id,
						status: siteData.value.status == false ? 0 : 1,
						name: siteData.value.name,
						prices_with_tax: siteData.value.prices_with_tax == false ? 0 : 1,
						economic_account_id: siteData.value.account_id,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_SITE,
						variables: { input: formData },
						update: (store, { data: { create_site } }) => {
							const pervious_record = store.readQuery({
								query: GET_SITES,
							}) as Record<any, any>;

							store.writeQuery({
								query: GET_SITES,
								data: {
									sites: [create_site, ...pervious_record.sites],
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addSiteForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("addSiteAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_site")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			siteData,
			addSiteForm,
			type,
			countries,
			showCountryInfo,
			changeConfig,
			submitHandlerForm,
		};
	},
});
