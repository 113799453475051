import { gql } from "graphql-tag";
import { SITE_FIELDS } from "@/modules/common/sites/graphql/Fragments";

export const CREATE_SITE = gql`
	mutation CreateSite($input: SiteInput!) {
		create_site(site: $input) {
			...SiteFields
		}
	}
	${SITE_FIELDS}
`;

export const UPDATE_SITE = gql`
	mutation UpdateSite($input: SiteInput!) {
		update_site(site: $input) {
			...SiteFields
		}
	}
	${SITE_FIELDS}
`;

export const DELETE_SITE = gql`
	mutation DeleteSite($id: Int!) {
		delete_site(id: $id) {
			...SiteFields
		}
	}
	${SITE_FIELDS}
`;

export default { CREATE_SITE, UPDATE_SITE, DELETE_SITE };
