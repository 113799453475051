import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SitesList = _resolveComponent("SitesList")!
  const _component_AddSite = _resolveComponent("AddSite")!
  const _component_EditSite = _resolveComponent("EditSite")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_SitesList)
    ]),
    _createVNode(_component_AddSite),
    _createVNode(_component_EditSite)
  ], 64))
}