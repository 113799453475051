
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { SiteUpdateFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { UPDATE_SITE } from "../graphql/Mutations";
import { GET_SITES } from "@/modules/common/sites/graphql/Queries";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit site",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const editSiteForm = ref<null | HTMLFormElement>(null);
		const countries = ref([]) as Record<any, any>;
		const showCountryInfo = ref([]) as Record<any, any>;

		// Given Store Form Interface
		const siteUpdateData = ref<SiteUpdateFormData>({
			name: "",
			type: "",
			country_id: null,
			status: 1,
			id: null,
			account_id: "",
			prices_with_tax: false,
		});

		const type = ref([
			{ label: "B2C", value: "B2C" },
			{ label: "B2B", value: "B2B" },
		]);

		// Get Countries
		const getCountries = () => {
			countries.value = [];
			const countriesData = Apollo.readQuery({
				query: GET_COUNTRIES,
			});

			const country = [] as Record<any, any>;
			countriesData.countries.forEach((element) => {
				country.push({
					label: element.name,
					value: element.id,
					locale: element.locale,
					currency: element.currency,
				});
			});

			countries.value = country;
		};

		getCountries();

		const changeConfig = (id: number) => {
			showCountryInfo.value = [];
			const config = countries.value.find((element) => element.value == id);
			showCountryInfo.value.push({
				Currency: config.currency,
				Locale: config.locale,
			});
		};

		// Submit Hanlder Request
		const submitHandlerForm = async () => {
			editSiteForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						id: siteUpdateData.value.id,
						type: siteUpdateData.value.type,
						country_id: siteUpdateData.value.country_id,
						name: siteUpdateData.value.name,
						status: siteUpdateData.value.status == false ? 0 : 1,
						prices_with_tax: siteUpdateData.value.prices_with_tax == false ? 0 : 1,
						economic_account_id: siteUpdateData.value.account_id,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_SITE,
						variables: { input: formData },
						update: (store, { data: { update_site } }) => {
							const pervious_record = store.readQuery({
								query: GET_SITES,
							}) as Record<any, any>;

							store.writeQuery({
								query: GET_SITES,
								data: {
									sites: [...pervious_record.sites],
								},
								variables: {
									id: update_site.id,
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			editSiteForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("editSiteActions", (site: Record<any, any>) => {
			resetForm();
			siteUpdateData.value.id = site.id;
			siteUpdateData.value.name = site.name;
			siteUpdateData.value.account_id = site.economic_account_id;
			siteUpdateData.value.country_id = site.country_id;
			siteUpdateData.value.type = site.type;
			siteUpdateData.value.status = site.status == 1 ? true : false;
			siteUpdateData.value.prices_with_tax = site.prices_with_tax == 1 ? true : false;

			modal.value = new Modal(document.getElementById("modal_edit_site")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			siteUpdateData,
			editSiteForm,
			countries,
			showCountryInfo,
			type,
			changeConfig,
			submitHandlerForm,
		};
	},
});
